import React, { useEffect, useState } from "react";
import DocAidBanner from "../../assets/WelcomeBanner.png";
import DocAidClip from "../../assets/DocAidClip.gif";
import { Link } from "react-router-dom";
import Mission from "../../assets/mission-white.png";
import Vision from "../../assets/Vector2.png";
import ReshitaLogo from "../../assets/image 12.png";


const HomePage = () => {

  const [isScrolling, setIsScrolling] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const handleImageSlide = (index) => {
    setActiveIndex(index);
  };
  const images = [DocAidClip, DocAidBanner];
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((previousPage) => (previousPage + 1) % numberOfSlides);
    }, 8000);
    return () => clearInterval(interval);
  }, []);
  const numberOfSlides = 2;
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 300) {
        // Adjust this value based on when you want to hide the buttons
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>

      <div
        data-section
        id="home"
        className=" xl:pt-5 md:pt-10 pt-20"
        data-te-carousel-init
        data-te-ride="carousel"
        style={{ marginBottom: "20px" }} // Add inline style here for margin-bottom
      >
        {/* Carousel items */}
        <div className=" w-full overflow-hidden after:clear-both after:block after:content-['']">
          {/* Slides images */}
          {images.map((image, index) => (
            <div
              key={index}
              className={` float-left ${activeIndex === index ? "" : "hidden"
                } w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none`}
              data-te-carousel-item
              style={{ backfaceVisibility: "hidden" }}
            >
              <img
                src={image}
                className="block w-full h-full object-cover"
                alt={`Slide ${index + 1}`}
                style={{
                  maxHeight: "750px", // Adjust maximum height for large screens
                  height: "auto", // Allow the image to adjust its height while maintaining aspect ratio
                }}
              />

              {/* Circle dot indicators */}
              <div
                className={`absolute bottom-18 left-0 right-0 flex justify-center ${isScrolling ? "hidden" : ""
                  } mt-4 ${index === 0 ? "mt-50" : ""}`}
                style={{ zIndex: "2" }} // Adjust zIndex to ensure dot indicators are above the image
              >
                {images.map((_, dotIndex) => (
                  <button
                    key={dotIndex}
                    type="button"
                    data-te-target="#carouselExampleCaptions"
                    data-te-slide-to={dotIndex}
                    onClick={() => handleImageSlide(dotIndex)}
                    className={`mx-1 box-content w-3 h-3 rounded-full cursor-pointer border border-blue-500 ${activeIndex === dotIndex ? "bg-blue-500" : "bg-white"
                      }`}
                    aria-current={activeIndex === dotIndex ? "true" : "false"}
                    aria-label={`Slide ${dotIndex + 1}`}
                  ></button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Second Section */}
    

      <section className="text-gray-600 body-font">
        <div className="container px-5 py-12 mx-auto">
          <div className="flex flex-col md:flex-row justify-between items-center md:items-start">
            <div className="md:w-1/2 mb-10 md:mb-0">
              <div className="rounded-lg h-full overflow-hidden mx-auto md:ml-0 mt-8">
                <img alt="content" className="h-20 w-60" src={ReshitaLogo} />
              </div>
            </div>
            <div className="md:w-1/2">
              <p className="leading-relaxed text-gray-900 text-xl">
                In the world, where technology is an essential integration in
                our regular life. The need for smart solutions is a necessity.
                <p className="mt-5">
                  Reshita envisions to provide an elegant, affordable, and
                  efficient IT solutions to local businesses.
                </p>
              </p>
              <p className="mt-4 text-center md:text-left">
                <Link to={"/reshita-product"} className="text-blue-600 font-bold">
                  Know More
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Second Section Ends */}

      {/* mission */}
      <section className="text-white body-font montserrat bg-[#21D0B3]">
        <div className="container px-5 xl:pl-12 py-12 md:py-24 mx-auto flex flex-wrap">
          <div className="flex flex-wrap -m-4">
            <div className="p-4 w-full md:w-1/2">
              <div className="flex rounded-lg p-8 flex-col">
                <div className="w-16 h-16 xl:w-20 mb-4 inline-flex items-center justify-center rounded-full flex-shrink-0">
                  <img className="img text-white" alt="mission" src={Mission} />
                </div>
                <div className="flex-grow">
                  <h2 className="text-white text-lg font-medium mb-3">
                    MISSION
                  </h2>
                  <p className="leading-relaxed text-base">
                    To provide efficient, affordable, and elegant solutions to
                    local businesses.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-4 w-full md:w-1/2">
              <div className="flex rounded-lg p-8 flex-col">
                <div className="w-16 h-16 xl:w-20 mb-4 inline-flex items-center justify-center rounded-full flex-shrink-0">
                  <img className="imga" alt="vision" src={Vision} />
                </div>
                <div className="flex-grow">
                  <h2 className="text-white text-lg font-medium mb-3">
                    VISION
                  </h2>
                  <p className="leading-relaxed text-base">
                    We aim to get everyone on board in this fast-changing
                    digital ecosystem.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* mission Ends*/}

      {/* Card Section */}
      <div className="flex flex-col md:flex-row items-center justify-around min-h-screen md:min-h-[89vh] bg-white p-4">
      <div className=" hidden max-md:block text-center md:text-left top-1/2 md:top-auto md:right-16 transform md:transform-none -translate-y-1/2 md:-translate-y-0 text-teal-500 text-3xl md:text-5xl">
          Hear it from <br />
          our people
        </div>
        <div className="flex items-center justify-start space-x-4 mb-4 md:mb-0">

       
         
          <div className="relative w-80 h-auto md:h-96 bg-blue-700 text-white p-6 rounded-lg shadow-lg ml-4">
            <p className="mb-4">
              "I'm thoroughly impressed with Doc-Aid. It streamlines patient
              records, enhances the overall experience, and significantly
              reduces administrative workload. The user-friendly interface,
              seamless appointment scheduling, billing, and robust security
              measures instill trust among staff and patients."
            </p>
          </div>
         
        </div>
        <div className="max-md:hidden text-center md:text-left top-1/2 md:top-auto md:right-16 transform md:transform-none -translate-y-1/2 md:-translate-y-0 text-teal-500 text-3xl md:text-5xl">
          Hear it from <br />
          our people
        </div>
      </div>

      {/* Card Section Ends*/}

      {/* Talk with Us */}
      <section class="text-white body-font bg-[#263544]">
        <div class="container px-5 py-24 mx-auto">
          <div class="lg:w-2/3 mx-auto flex flex-col sm:flex-row sm:items-center justify-between ">
            <div class="sm:pr-16 mb-4">
              <h2 class="text-4xl mb-4">Talk with Us</h2>
              <p class="text-xl leading-relaxed">
                Want to know more about our products? Reach <br />
                out to us and we'll have a Reshita representative <br />
                get in touch with you.
              </p>
            </div>
            <Link to={"/contact"}>
            <button class="flex-shrink-0 bg-blue-700 text-[#FFFFFF] font-bold border-0 py-2 px-4 sm:px-8 focus:outline-none rounded text-lg mt-4 sm:mt-0 ">
              REACH OUT
            </button>
            </Link>
          </div>
        </div>
      </section>

      {/* Talk with Us Ends*/}
    </>
  );
};

export default HomePage;
